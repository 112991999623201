@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  overflow-x: hidden;
}

h1 {
  margin-bottom: 10px;
  margin-top: 30px;
  font-weight: 400;
}

.header {
  background-color: #0d122b;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100vw;
  margin-bottom: 20px;
}

.header>img {
  height: 40px;
  padding-left: 20px;
}

h2 {
  font-size: 35px;
  margin-bottom: 30px;
}

.left {
  height: 30px;
  gap: 20px;
}

.titleholder {
  margin: auto;
  color: #333333;
  font-size: 2.8em;
}

#holder {
  display: flex;
  float: left;
  margin-bottom: 50px;
  flex-wrap: wrap;
  justify-content: center;
}

.formHolder {
  min-height: 100vh;
  height: 50px;
  margin: auto;
}

.resultsTitle {
  font-size: 14px;
  font-weight: bold;
  font-family: "Cabin Condensed", sans-serif;
}

.resultContainer {
  text-align: center;
  margin-bottom: 10px;
}

.resultDiv {
  text-align: center;
  margin-top: 10px;
}

.resultDiv>p {
  margin: 10px;
}

.resultDiv>p:first-child {
  font-weight: bold;
}

.resultPage {
  height: 400px;
  max-width: 100%;
  border: 1px solid black;
}

.resultHolder {
  width: 90%;
}

.individualResult {
  width: 250px;
  margin-right: 30px;
  margin-top: 20px;
}

input[type="file"] {
  width: 280px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #0d45a5;
}

.drop-container {
  width: 346px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding: 5px;
  border-radius: 5px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.titleholder>a {
  text-decoration: none;
  font-size: 25px;
  color: black;
  margin: 20px;
}

.homepage {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 350px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 6px;
  flex-wrap: wrap;
}

.button {
  border-radius: 3px;
  width: 116px;
  height: 40px;
}

/* .decode-button {
  background-color: white;
  border: 1px solid black;
}

.decode-button:hover {
  background: #eee;
  border-color: #111;
  transition: background 0.2s ease-in-out;
} */

/* .upload-button {
  border-radius: 3px;
  width: 116px;
  height: 40px;
  color: white;
  background: #084cdf;
  border: 1px solid #084cdf;
}

.upload-button:hover {
  background: white;
  color: #084cdf;
  transition: background 0.2s ease-in-out;
} */

.upload-button:disabled {
  opacity: 0.5;
}

.button-input {
  padding: 7px;
  background: #084cdf;
  color: white;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  width: 45px;
}

.edit-button {
  display: none;
  cursor: pointer;
  width: 45px;
}

#error-code {
  display: none;
  color: red;
  font-size: 14px;
  text-align: left;
  margin-left: -25px;
  margin-top: 4px;
}

input[type="text"] {
  padding: 5px;
  margin-right: 5px;
  border-radius: 3px;
  border: 1px solid #c1c1c1;
}

input[type="text"]:disabled {
  background-color: rgb(230, 229, 229);
}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #39b4ac;
  border-radius: 20px;
}

.swal2-icon {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.delete-button {
  margin-right: 0;
  margin-left: 91%;
  margin-bottom: -26px;
  border: 0;
  background: 0;
  cursor: pointer;
}

.delete-button>svg {
  color: red;
}

@media only screen and (max-width: 360px) {
  .drop-container {
    width: 290px;
  }
}